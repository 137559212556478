import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import anime from "animejs";

import { ReactComponent as UsersIcon } from "./assets/icons/Users.svg";
import { ReactComponent as ToolsIcon } from "./assets/icons/Tools.svg";
import { ReactComponent as StarIcon } from "./assets/icons/Star.svg";
import { ReactComponent as ChartDownIcon } from "./assets/icons/ChartDown.svg";
import { ReactComponent as TimeIcon } from "./assets/icons/Time.svg";
import DisplayRowContainer from "./DisplayRowContainer";
// const videoSrc = require("/home/alialperena/ahde-web/src/assets/video.mp4");

const HomePage = () => {
  const [ref3, inView3] = useInView();
  const animRef = React.useRef(null);
  const [animationStarted, setAnimationStarted] = useState(false);
  const text = "Ahde Zemin Sports";
  const letters = text.split("").map((char, index) => (
    <span key={index} className="letter">
      {char}
    </span>
  ));

  const videoSrc =
    "https://nextcloud.ahdezemin.com/s/FrDP2wqEkBLnBaT/download/327-Untitled%20video%20-%20Made%20with%20Clipchamp.mp4";

  useEffect(() => {
    anime
      .timeline({
        loop: false,
        delay: anime.stagger(200), // Her bir harf için 100ms gecikme ekler
      })
      .add({
        targets: ".an-1 .letter",
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 500,
      });
  }, []);

  useEffect(() => {
    if (inView3 && !animationStarted) {
      animRef.current = anime({
        targets: ".card-animation",
        translateY: [150, 0],
        easing: "easeOutExpo",
        opacity: [0, 1],
        delay: 200,
      });
      setAnimationStarted(true);
    }
  }, [inView3, animationStarted]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Tüm öğeler için sol animasyonunu tanımlayın
          const animation = anime({
            targets: entry.target,
            translateX: [-100, 0], // Soldan kaybolma animasyonu
            opacity: [0, 1],
            duration: 800,
            easing: "easeOutQuad",
          });
          console.log(animation);
          observer.unobserve(entry.target); // Gözlemciyi durdurun
        }
      });
    });

    // document.querySelectorAll(".grid-cols-1 > article").forEach((el, i) => {
    //   el.setAttribute("data-index", i); // Her bir öğeye bir indeks atayın (Bu artık gerekli olmayabilir)
    //   observer.observe(el); // Öğeyi gözlemciye ekleyin
    // });
    document.querySelectorAll(".custom-animataion-div").forEach((el, i) => {
      el.setAttribute("data-index", i); // Her bir öğeye bir indeks atayın (Bu artık gerekli olmayabilir)
      observer.observe(el); // Öğeyi gözlemciye ekleyin
    });

    return () => observer.disconnect(); // Bileşen kaldırıldığında gözlemciyi temizleyin
  }, []);

  const services = [
    {
      title: "Basketbol Sahası Yapımı",
      image: "./assets/basketbol-page/basketbol-main.jpg",
      id: "basketball",
    },
    {
      title: "Tenis Sahası Yapımı",
      image: "./assets/tenis-page/t-3.png",
      id: "tenis",
    },
    {
      title: "Voleybol Sahası Yapımı",
      image: "./assets/voleybol-page/v-1.png",
      id: "voleybol",
    },
    {
      title: "Halı Saha Yapımı",
      image: "./assets/halisaha-page/h-3.jpg",
      id: "halisaha",
    },
    {
      title: "Atletizm Pisti Yapımı",
      image: "./assets/atletizm-page/a-1.jpg",
      id: "atletizm",
    },
    {
      title: "Basketbol Sahası Yapımı",
      image: "./assets/basketbol-page/basketbol-main.jpg",
      id: "basketball",
    },
    {
      title: "Tenis Sahası Yapımı",
      image: "./assets/tenis-page/t-3.png",
      id: "tenis",
    },
    {
      title: "Voleybol Sahası Yapımı",
      image: "./assets/voleybol-page/v-1.png",
      id: "voleybol",
    },
    {
      title: "Halı Saha Yapımı",
      image: "./assets/halisaha-page/h-3.jpg",
      id: "halisaha",
    },
    {
      title: "Atletizm Pisti Yapımı",
      image: "./assets/atletizm-page/a-1.jpg",
      id: "atletizm",
    },
    {
      title: "Basketbol Sahası Yapımı",
      image: "./assets/basketbol-page/basketbol-main.jpg",
      id: "basketball",
    },
    {
      title: "Tenis Sahası Yapımı",
      image: "./assets/tenis-page/t-3.png",
      id: "tenis",
    },
    {
      title: "Voleybol Sahası Yapımı",
      image: "./assets/voleybol-page/v-1.png",
      id: "voleybol",
    },
    {
      title: "Halı Saha Yapımı",
      image: "./assets/halisaha-page/h-3.jpg",
      id: "halisaha",
    },
    {
      title: "Atletizm Pisti Yapımı",
      image: "./assets/atletizm-page/a-1.jpg",
      id: "atletizm",
    },
  ];
  const floorCoatingSystems = [
    {
      title: "Akrilik Zemin Kaplama",
      image: "./assets/akrilikZemin-page/akrilik-5.png",
      id: "akrilikZemin",
    },
    {
      title: "Epoksi Zemin Kaplama",
      image: "./assets/epoksiZemin-page/epoksi-1.png",
      id: "epoksiZemin",
    },
    {
      title: "Asfalt Zemin Kaplama",
      image: "./assets/asfaltZemin-page/asfalt-1.jpg",
      id: "asfaltZemin",
    },
    {
      title: "Sentetik Çim Zemin Kaplama",
      image: "./assets/sentetikCimZemin-page/s-2.jpg",
      id: "sentetikCimZemin",
    },
    {
      title: "Kauçuk Zemin Kaplama",
      image: "./assets/kaucukZemin-page/k-1.png",
      id: "kaucukZemin",
    },
  ];

  const products = [
    {
      title: "Basketbol Potası",
      image: "./assets/spor-ekipmanlari/basketbol-potasi-1.jpg",
      id: "basketbolPotasi",
    },
    {
      title: "Futbol Kalesi",
      image: "./assets/spor-ekipmanlari/futbol-kalesi-1.jpg",
      id: "futbolKalesi",
    },
    {
      title: "Tenis Ekipmanları",
      image: "./assets/spor-ekipmanlari/tenis-diregi-1.jpg",
      id: "tenisEkipmanlari",
    },
    {
      title: "Voleybol Direği",
      image: "./assets/spor-ekipmanlari/voleybol-diregi-1.jpg",
      id: "voleybolDiregi",
    },
    {
      title: "Yedek Kulübesi",
      image: "./assets/spor-ekipmanlari/Yedek-Kulubesi-1.jpg",
      id: "yedekKulubesi",
    },
  ];
  const references = [
    {
      title: "Irak – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı",
      year: "2024",
    },
    {
      title:
        "Şırnak Cudi Cup Turnuvası - Akrilik Zemin Kaplama, Tenis Sahası Yapımı, Tel Çit Örgü",
      year: "2023-2024",
    },
    {
      title: "Trabzon – Epoksi Zemin Kaplama",
      year: "2023",
    },
    {
      title: "Eskişehir KYK Yurdu – Akrilik Zemin Kaplama, Tenis Sahası Yapımı",
      year: "2023",
    },
    {
      title:
        "Sakarya/Hendek - Akrilik Zemin Kaplama, Basketbol Sahası, Tribün Koltukları",
      year: "2022",
    },
    {
      title:
        "Afganistan – Akrilik Zemin Kaplama, Basketbol Sahası Yapımı, Voleybol Sahası Yapımı",
      year: "2022",
    },
  ];
  return (
    <div className="py-0  bg-gray-100">
      <Helmet>
        <title>Ahde Zemin Sports - Ana Sayfa</title>
        <link href="/" />
      </Helmet>
      {/* section */}
      <div className="">
        <div className="w-full h-full object-cover relative">
          <section className="min-h-[300px] max-h-[750px] relative flex flex-1 shrink-0 items-center justify-center overflow-hidden bg-gray-100 shadow-lg">
            <div className="relative w-full h-full backdrop-blur-3xl">
              <video
                autoPlay
                muted
                loop
                className="w-full h-full object-cover min-h-[300px]"
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="absolute inset-0 flex items-center justify-center animated-text-background">
                <div className="text-center">
                  <h1 className="an-1 mb-8 text-left text-4xl font-bold  text-red-600 sm:text-5xl md:mb-12 md:text-6xl">
                    {letters}
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="mt-16 px-24 flex items-center justify-center p-4">
        <h3 className="text-3xl">Neden biz?</h3>
      </div>
      <div className="block px-16 ">
        <div ref={ref3} className=" card-animation">
          <p className="pt-8">
            Sektöründe 30 yıla yakın deneyimi olan uzman ekiplerimizle AHDE
            ZEMİN SPORTS (ZEMİN SPORTS 2009) çatısı altında 2021 yılında isim
            değişikliği ile yenilenen firmamız, aynı anda bir çok projeyi,
            zengin ürün yelpazesi ve üstün kalite ile yürütebilmektedir. Geniş
            kadro ve hizmet ağımızla her bütçeye uygun seçenekler sunuyor, çözüm
            ortaklarımızın markalarına hacim ve değer katıyoruz. Daimi inovasyon
            vizyonumuz ile yeni teknolojileri inceliyor, üretiyor ve
            projelerimize dahil ediyoruz. Projelerimizi, her aşamasında özenle
            takip etmemiz, en iyi şekilde ve zamanında teslim ediyor olmamız,
            bugünkü güven ve saygınlığa ulaşmamızın daimi sebebidir.
          </p>
          <div className="grid grid-cols-5 max-md:grid-cols-1 gap-4 p-6 mt-8 mb-8">
            <div className="col-span-1 flex flex-col items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full border-2 border-gray-200 hover:border-red-500 flex items-center justify-center cursor-pointer">
                <UsersIcon fill="#dc2626" />
              </div>
              <h3 className="text-xl mt-2">Profesyonel Ekip</h3>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full border-2 border-gray-200 hover:border-red-500 flex items-center justify-center cursor-pointer">
                <ToolsIcon fill="#dc2626" />
              </div>
              <h3 className="text-xl mt-2">Garanti & Kalite</h3>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full border-2 border-gray-200 hover:border-red-500 flex items-center justify-center cursor-pointer">
                <StarIcon fill="#dc2626" />
              </div>
              <h3 className="text-xl mt-2">Kaliteli Ürün</h3>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full border-2 border-gray-200 hover:border-red-500 flex items-center justify-center cursor-pointer">
                <ChartDownIcon fill="#dc2626" />
              </div>
              <h3 className="text-xl mt-2">Uygun Fiyat</h3>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full border-2 border-gray-200 hover:border-red-500 flex items-center justify-center cursor-pointer">
                <TimeIcon fill="#dc2626" />
              </div>
              <h3 className="text-xl mt-2">7/24</h3>
            </div>
          </div>
          <div className="mt-16 px-24 flex items-center justify-center p-4 bg-red-500 text-white rounded-lg shadow-lg">
            <p className="text-2xl italic font-semibold">
              "Başarımızın anahtarı bizi rakip görenlerle yarışmak değil,
              sizlere sunmuş olduğumuz hizmetin kalitesidir."
            </p>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-16 md:py-16 ">
        <div className="col-span-1">
        <img
                  src={'/assets/why-us.png'}
                  alt="Why Us"
                  className="h-full w-full pr-4 max-sm:hidden"
                />
        </div>
        <div ref={ref3} className="col-span-1 card-animation">
          <p className="pt-8">
            Zemin Kaplama & Spor Yapı Projeleri Sektöründe 20 yıla yakın
            deneyimi olan uzman ekiplerimizle Ahde Zemin Sports çatısı altında
            2013 yılında kurulan firmamız, aynı anda bir çok projeyi, zengin
            ürün yelpazesi ve üstün kalite ile yürütebilmektedir. Geniş kadro ve
            hizmet ağımızla her bütçeye uygun seçenekler sunuyor, çözüm
            ortaklarımızın markalarına hacim ve değer katıyoruz. Daimi inovasyon
            vizyonumuz ile yeni teknolojileri inceliyor, üretiyor ve
            projelerimize dahil ediyoruz. Projelerimizi, her aşamasında özenle
            takip etmemiz, en iyi şekilde ve zamanında teslim ediyor olmamız,
            bugünkü güven ve saygınlığa ulaşmamızın daimi sebebidir.
            Saygılarımızla…
          </p>
        </div>
      </div> */}

      {/* gallery */}
      <div ref={ref3} className="bg-white pt-16 pb-16 px-0 ">
        <h3 className="text-center text-2xl font-semibold text-gray-700 my-8">
          Hizmetlerimiz
        </h3>
        <div className="custom-animation-div px-4 lg:px-8">
          <article>
            <DisplayRowContainer
              className="row-container"
              title={"Hizmetlerimiz"}
              data={services}
              url="/service-detail"
            />
          </article>
        </div>
        <h3 className="text-center text-2xl font-semibold text-gray-700 mt-16 mb-8">
          Zemin Kaplama Sistemleri
        </h3>
        <div className="custom-animation-div px-4 lg:px-8">
          <article>
            <DisplayRowContainer
              className="row-container"
              title={"Zemin Kaplama Sistemleri"}
              data={floorCoatingSystems}
              url="/floorCoating-detail"
            />
          </article>
        </div>
        <h3 className="text-center text-2xl font-semibold text-gray-700 mt-16 mb-8">
          Spor Ekipmanları
        </h3>
        <div className="custom-animation-div px-4 lg:px-8">
          <article>
            <DisplayRowContainer
              className="row-container"
              title={"Spor Ekipmanları"}
              data={products}
              url="/product-detail"
            />
          </article>
        </div>
      </div>

      <div className="px-32 py-32 bg-gradient-to-b from-gray-50 via-white to-gray-300">
        <h3 className="text-center text-2xl font-semibold text-gray-700 py-8">
          Referanslarımız
        </h3>
        <div className="flex flex-wrap justify-between items-center gap-4">
          <img
            src="./assets/reference/referans-1.jpg"
            alt="Logo 1"
            className="w-24 h-24"
          />
          <img
            src="./assets/reference/referans-2.png"
            alt="Logo 2"
            className="w-24 h-24"
          />
          <img
            src="./assets/reference/referans-3.png"
            alt="Logo 3"
            className="w-24 h-24"
          />
          <img
            src="./assets/reference/referans-4.png"
            alt="Logo 4"
            className="w-24 h-24"
          />
          <img
            src="./assets/reference/referans-5.png"
            alt="Logo 5"
            className="w-24 h-24"
          />
          <img
            src="./assets/reference/referans-6.jpg"
            alt="Logo 6"
            className="w-24 h-24"
          />
        </div>
        <div className="flex flex-col space-y-4 mt-16">
          <div className="pt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {references.map((reference, index) => (
              <div key={index} className="relative w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-red-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="ml-6">
                  <h4 className="font-bold text-black-500">
                    {reference.title}
                  </h4>
                  <span className="mt-1 block text-sm font-semibold text-red-500">
                    {reference.year}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => {
                window.location.href = "/references";
              }}
              className="px-4 py-1 text-sm text-white bg-red-500 font-semibold rounded-full border hover:text-white"
            >
              Daha fazla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
