// import React from "react";
// import { Helmet } from "react-helmet";

// const ProductsPage = () => {
//   if (loading)
//     return (
//       <div className="flex flex-col items-center justify-center min-h-screen py-2 px-8">
//         <h1 className="text-sm text-black font-bold rounded">Loading...</h1>
//       </div>
//     );
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen py-2 px-8">
//       <Helmet>
//         <title>Ürünlerimiz</title>
//         <link
//           rel="/"
//           href="https://venerable-phoenix-f6f2be.netlify.app/products"
//         />
//       </Helmet>
//       <h1 className="text-sm text-black font-bold rounded">Ürünler</h1>
//       <div className="grid grid-cols-3 gap-4 mt-6 max-sm:grid-cols-1">
//         {products.map((product, index) => (
//           <div>
//             <a
//               href={"product" / product.id}
//               className="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3"
//             >
//               <img
//                 alt={product.name || product.id}
//                 src={product.image}
//                 loading="lazy"
//                 className="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
//               />
//             </a>

//             <div>
//               <a
//                 href="/"
//                 className="hover:gray-800 mb-1 text-gray-500 transition duration-100 lg:text-lg"
//               >
//                 {product.title}
//               </a>

//               <div className="flex items-end gap-2">
//                 <span className="font-bold text-gray-800 lg:text-lg">
//                   {product.price}$
//                 </span>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductsPage;
