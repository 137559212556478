// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.swiper {
  width: 100%;
  height: 100%;
  background-color: red !important;
}

/* styles.css */
.swiper-button-next, .swiper-button-prev {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}


.custom-swiper-slide{
  background-color: red !important;
}

.swiper-horizontal {
  .swiper-button-next,
  .swiper-button-prev {
    left: 50%;
    transform: rotate(90deg);
    transform-origin: left center;
  }

  .swiper-button-prev {
    top: 10px;
  }

  .swiper-button-next {
    top: auto;
    bottom: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/swiper.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,YAAY;EACZ,gCAAgC;AAClC;;AAEA,eAAe;AAEb;EAAA,kBAA4B;EAA5B,uDAA4B;EAA5B,oBAA4B;EAA5B;AAA4B;;;AAI9B;EACE,gCAAgC;AAClC;;AAEA;EACE;;IAEE,SAAS;IACT,wBAAwB;IACxB,6BAA6B;EAC/B;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,SAAS;IACT,YAAY;EACd;AACF","sourcesContent":["\n.swiper {\n  width: 100%;\n  height: 100%;\n  background-color: red !important;\n}\n\n/* styles.css */\n.swiper-button-next, .swiper-button-prev {\n  @apply bg-red-500 text-white;\n}\n\n\n.custom-swiper-slide{\n  background-color: red !important;\n}\n\n.swiper-horizontal {\n  .swiper-button-next,\n  .swiper-button-prev {\n    left: 50%;\n    transform: rotate(90deg);\n    transform-origin: left center;\n  }\n\n  .swiper-button-prev {\n    top: 10px;\n  }\n\n  .swiper-button-next {\n    top: auto;\n    bottom: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
